.date-nav {
  display: grid;
  margin: auto;
  margin-top: 5px;
  grid-template-columns: auto 1fr auto;

  .current-day-or-range {
    justify-self: center;
    align-self: center;
    font-weight: 700;
    font-size: 16px;
  }

  .total-spend-period, .total-spend-description {
    grid-column-start: 2;
    grid-column-end: 2;
    justify-self: center;
    margin-bottom: 10px;
  }

  .total-spend-period {
    font-weight: 800;
    font-size: 36px;
    margin-top: 10px;
  }
  

  @import './NavLinks.scss';
}



