$small: 428px;
$medium: 923px;
$large: 1440px;


/***
EXAMPLES:

@include sm-down {
  font-size: 0.8rem;
}

@include sm-down {
  padding: 0.8rem;
}
***/

@mixin sm-down {
    @media (max-width: #{$small}) {
        @content;
    }
}

@mixin sm-only {
    @media (min-width: 0) and (max-width: #{$small}) {
        @content;
    }
}

@mixin sm-up {
    @media (min-width: #{$small}) {
        @content;
    }
}

@mixin md-down {
    @media (max-width: #{$medium}) {
        @content;
    }
}

@mixin md-down-portrait {
    @media (max-width: #{$medium}) and (orientation: portrait) {
        @content;
    }
}

@mixin md-down-landscape {
    @media (max-width: #{$medium}) and (orientation: landscape) {
        @content;
    }
}




@mixin md-only {
    @media (min-width: #{$small}) and (max-width: #{$medium}) {
        @content;
    }
}

@mixin md-up {
    @media (min-width: #{$medium}) {
        @content;
    }
}

@mixin lg-down {
    @media (max-width: #{$large}) {
        @content;
    }
}

@mixin lg-only {
    @media (min-width: #{$medium}) and (max-width: #{$large}) {
        @content;
    }
}

@mixin lg-up {
    @media (min-width: #{$large}) {
        @content;
    }
}

@mixin print {
    @media print {
        @content;
    }
}
