.navigation {
  display: grid;
  color: #2c365d;
  .summary {
    font-size: 16px;
  }

  .resp-side-by-side {
    display: grid;
    align-items: center;
  }

  $border-radius: 5rem;
  .costed-uncosted-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0px;
    grid-template-rows: auto 1fr;
    width: 400px;

    .costedToggle {
      display: block;
      padding: 10px;
      text-align: center;
      font-weight: 900;
      border: 1px solid #4eeeca;
      color: inherit;
      background-color: white;
      text-decoration: none;
      height: 38px;
      text-align: center;
      line-height: 34px;

      &.uncostedLink {
        border-radius: $border-radius 0 0 $border-radius;
      }
      &.costedLink {
        border-radius: 0 $border-radius $border-radius 0;
      }

      &.activeMode {
        background-color: #2cd9a8;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .navigation {
    grid-template-rows: 1fr;
    grid-gap: 40px;
    margin-top: 40px;
    margin-bottom: 40px;

    & > div {
      padding-left: 45px;
      padding-right: 45px;
    }

    div.resp-side-by-side {
      grid-template-columns: 1fr;

      .costed-uncosted-links {
        margin-top: 25px;
        width: 100%;
        .costedToggle {
          display: grid;
          align-content: center;
        }
      }
    }
  }
}

@media only screen and (min-width: 901px) {
  .navigation {
    grid-template-rows: auto auto;

    .resp-side-by-side {
      margin: 80px 0px 80px 0px;
      grid-template-columns: 3fr 1fr 1fr;
      grid-column-gap: 10px;
    }

    .summary {
      justify-self: left;
      text-align: left;
    }

    .costed-uncosted-links {
      grid-column-start: 3;
    }
  }
}

@media only screen and (min-width: 1571px) {
  .screenSize::before {
    content: "TopSummary min-width: 1571";
    display: block;
  }
  .navigation {
    .costed-uncosted-links {
      .costedToggle {
        font-size: 0.8vw;
      }
    }
  }
}

@media only screen and (min-width: 2791px) {
  .screenSize::before {
    content: "TopSummary min-width: 1571";
    display: block;
  }
  .navigation {
    .costed-uncosted-links {
      .costedToggle {
        font-size: 0.6vw;
      }
    }
  }
}


@media only screen and (max-width: 1570px) {
  .screenSize::before {
    content: "TopSummary max-width: 1570";
    display: block;
  }
  .navigation {
    .costed-uncosted-links {
      .costedToggle {
        font-size: 1.4vmin;
      }
    }
  }
}

@media only screen and (max-width: 1365px) {
  .screenSize::before {
    content: "TopSummary max-width: 1365";
    display: block;
  }
  .navigation {
    .costed-uncosted-links {
      .costedToggle {
        font-size: 1.2vmin;
      }
    }
  }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .screenSize::before {
    content: "TopSummary max-width 1200";
    display: block;
  }

  .navigation {
    div.resp-side-by-side {
      grid-template-columns: auto auto;
      .costed-uncosted-links {
        .costedToggle {
          font-size: 1.5vmin;
        }
      }
    }
  }
}
@media only screen and (max-width: 1005px) and (orientation: portrait) {
  .screenSize::before {
    content: "TopSummary max-width: 1005 orientation portrait";
    display: block;
  }
  .navigation {
    .costed-uncosted-links {
      .costedToggle {
        font-size: 2.7vmin;
      }
    }
  }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .screenSize::before {
    content: "TopSummary max-width: 1005 orientation portrait";
    display: block;
  }
  .navigation {
    .costed-uncosted-links {
      .costedToggle {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .summary {
    justify-self: left;
    text-align: left;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1005px) and (orientation: landscape) {
  .screenSize::before {
    content: "TopSummary max-width: 1005 orientation landscape";
    display: block;
  }
  .navigation {
    .costed-uncosted-links {
      .costedToggle {
        font-size: 3.2vmin;
      }
    }
  }
}
