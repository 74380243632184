.summary-nav {
  background-color: white;
  padding-top: 48px;
  padding-bottom: 48px;
  .nav-day-week {
    margin-top: 20px;
  }

  .hero-value {
    img.trend-down {
      transform: rotate(180deg);
    }
  }
}

@media only screen and (max-width: 1100) {
  .date-nav .current-day-or-range.weekly {
    font-size: 1.5vmin;
  }
}

@media only screen and (max-width: 900px) {
  .summary-nav {
    .hero-insight {
      display: none;
    }
  }
}

@media only screen and (max-width: 900px) and (orientation: portrait) {
  .summary-nav {
    .nav-day-week {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .summary-nav {
    .nav-day-week {
      margin-left: 25%;
      margin-right: 25%;
    }
  }
}

@media only screen and (min-width: 1081px) {
  .nav-day-week {
    margin-left: 25%;
    margin-right: 25%;
  }
}
@media only screen and (max-width: 1080px) {
  .nav-day-week {
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media only screen and (min-width: 901px) {
  .summary-nav {
    display: grid;
    grid-template-columns: 0.5fr auto 5fr auto 0.5fr;
    align-items: center;

    .hero-insight {
      &:first-child {
        grid-column-start: 2;
      }

      display: grid;
      grid-template-rows: auto auto;
      align-items: center;
      justify-items: center;

      .hero-value {
        margin-top: 10px;
        width: 90px;
        height: 90px;
        display: grid;
        grid-template-rows: 1fr;
        align-items: middle;
        justify-items: center;
        font-size: 18px;

        img {
          margin-right: 10px;
        }

        & > div {
          display: flex;
          align-items: center;
        }
      }

      .hero-byline {
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .summary-nav {
    padding-top: 1px;
    padding-bottom: 10px;
  }
}
