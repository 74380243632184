@use "./shared.scss" as shared;

.not-found {
  .main-section {
    min-height: 80vh;
    display: grid;
    position: relative;
    grid-template-rows: 1fr auto auto 1fr;
    justify-items: center;

    .headline {
      grid-row-start: 2;
      font-weight: 400;
      font-size: 36px;
      text-align: center;
    }

    .content {
      text-align: center;
      grid-row-start: 3;
      font-weight: 400;
      font-size: 20px;
    }
    .account-not-found-img {
      padding: 60px 0px;
    }
  }
}
.account-not-found {
  .img {
    padding: 60px 0px;
  }
  .link {
    color: #2c365d;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1005px) {
  .not-found .main-section {
    padding-left: 12vw;
    padding-right: 12vw;
  }
}
