@import "/public/responsive";

.drill-down {
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  & > div:not(.insights-panel) {
    justify-self: center;
  }

  .rotate-phone {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 3px;
    }
  }

  display: grid;
  grid-template-columns: 1fr;
  margin-top: 80px;
  color: #2c365d;

  .drill-down-title {
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 5px;
  }
  .drill-down-subtitle {
    font-weight: bold;
    font-size: 18px;
    color: #bbbfbc;
  }

  .loading {
    display: grid;
    grid-template-columns: 1fr;
    height: 80px;
  }

  .circle-animation {
    justify-self: center;
    align-self: middle;
    margin-top: 10px;
    width: 50px;
    height: 50px;
    margin-left: 25px;
    border: 3px solid #ffffff;
    border-top-color: #bbbfbc;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  .hidden {
    display: none;
  }
}

@media screen and (orientation: landscape) {
  .drill-down {
    .rotate-phone {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .drill-down {
    margin-top: 48px;
    .drill-down-title {
      font-size: 32px;
      margin-bottom: 0px;
    }
    .insights-panel {
      .insights-content {
      .insight.selected {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
      margin-top: 0px;
      margin-bottom: 48px;
    }
  }
}

@include md-down {
  .drill-down {
    grid-row-gap: 25px;
  }
}

@include md-up {
  .drill-down {
    .drill-down-subtitle {
      margin-top: 25px;
    }
    .insights-panel {
      .insights-content {
        justify-content: space-evenly;
      }

      margin-top: 0px;
    }
  }
}
