@use "./shared.scss" as shared;

.trust-app {
  &:not(.loading) {
    grid-template-rows: 0.75fr 1.5fr 0.5fr 0.6fr 1fr 4fr 1fr;
  }
  &.loading {
    grid-template-rows: auto 1fr auto;
  }

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .loading-main {
    position: relative;
  }

  .circle-animation {
    position: absolute;
    width: 50px;
    height: 50px;
    margin-top: 40vh;
    margin-left: 50vw;
    border: 3px solid #ffffff;
    border-top-color: #000000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  .refetch-loading-panel {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;

    &.hidden {
      display: none;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.trust-app {
  background-color: shared.$content-background;
  text-align: center;
  .usage-data {
    background-color: #ffffff;
    &.no-data {
      min-height: 50vh;
      .message {
        font-weight: 700;
        font-size: 24px;
        margin: auto;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .usage-graph-container {
    margin: 0px 15px;
  }
}

@media only screen and (min-width: 901px) {
  .trust-app {
    & > div:not(.header, .footer) {
    }
  }
}
