@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Calibri:wght@300;400;700;900&display=swap");

body {
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container {
  margin-right: auto;
  margin-left: auto;
  width: 1280px;
  background-color: #f5f5f5;
  padding: 0px 50px;
}

.alert {
  padding: 5px;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr auto;
  top: 5vh;
  top: var(--alert-message-top, 5vh);
  left: 50%;
  width: 50vw;
  transform: translateX(-50%);
  color: white;
  span {
    justify-self: center;
  }
  &.hidden {
    display: none;
  }
  &.ok {
    background-color: #2CD9A8;
  }

  &.error {
    background-color: #f28383;
  }

  button {
    background-color: transparent;
    border: none;
  }
}

@media only screen and (max-width: 1005px) {
  .alert,
  .alert button {
    font-size: 3.2vmin;
  }
}

@media only screen and (max-width: 1005px) {
  .alert {
    grid-template-columns: auto auto;

    button {
      padding: 0px;
      margin: 0px;
    }
  }
}

@media only screen and (max-width: 1005px) and (orientation: landscape) {
  .alert,
  .alert button {
    font-size: 3.4vmin;
  }

  .alert {
    padding: 1px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0px 0px;
    margin: 0px;
  }
}
