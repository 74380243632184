$average: #4f4f4f;
$background: #f8f8f8;
$blue: #33b2e1;
$budget: #780ce8;
$charcoalGrey: #444444;
$consumption: #01e7ff;
$cyan: #02e3ff;
$darkBlue: #0069fa;
$darkOrange: #eb7325;
$generation: #0074fb;
$green: #bed600;
$grey: #999999;
$input-border: #dee5ec;
$lightBlue: #ebf4ff;
$lightGrey: #f1f1f1;
$navyBlue: #0f66ae;
$olive: #879327;
$orange: #fe9000;
$red: #d7253b;
$supply: #c1bbc3;
$text: #606e78;
$white: #ffffff;
$whiteLabelAverage: #004081;
$whiteLabelAxisLine: #e4e6e5;
$whiteLabelAxisLineZero: #333333;
$whiteLabelDailySupply: #2cd9a8;
$whiteLabelEstimatedUsage: #3ab297;
$whiteLabelSolarExport: #ff8a78;
$whiteLabelSupply: #2cd9a8;
$whiteLabelTick: #737373;
$whiteLabelUsage: #59e0ff;
$yellow: #f4ca00;
