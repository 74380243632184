@use "./colours.scss" as colours;
@import "/public/responsive";

.usage-data {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    "graph-container"
    "legend-container";
  .tooltip-container, .legend-tooltip-container {
    &.transparent {
      opacity: 0;
    }

    &:not(.visible) {
      display: none;
    }

    z-index: 10;
    border-radius: 8px;
    color: #ffffff;
  }

  .tooltip-container {
    position: absolute;
    background-color: #2c365d;
    padding: 25px;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: var(--bubble-point-left, calc(50% - 5px));
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: #2c365d;
      border-bottom: 0;
      margin-left: var(--bubble-point-margin-left, 0);
      margin-bottom: -10px;
    }

    &.left:after {
      z-index: 1000;
      margin-left: -15px;
      bottom: 20px;
      transform: rotate(90deg);
    }

    &.right:after {
      transform: rotate(-90deg);
      margin-bottom: 10px;
    }

    .tooltip-title,
    .tooltip-subtitle {
      font-weight: 700;
      text-align: left;
    }

    .tooltip-title {
      font-size: 16px;
    }

    .tooltip-subtitle {
      margin-top: 15px;
      font-size: 14px;
    }

    .tooltip-data {
      display: grid;
      grid-template-columns: auto 1fr;
      row-gap: 15px;
      margin-top: 15px;
      align-items: center;
      justify-items: left;

      & > div {
        &.data-point {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 5px;
        }

        &.usage {
          background-color: colours.$whiteLabelUsage;
        }

        &.solar {
          background-color: colours.$whiteLabelSolarExport;
        }

        &.supply {
          background-color: colours.$whiteLabelSupply;
        }

        &.average {
          background-color: colours.$whiteLabelAverage;
        }
      }
    }

    .graph-container {
      overflow-y: hidden;
      .chart {
        width: 100%;

        .x-axis {
          .tick {
            text {
              font-size: 20px;
            }
          }
        }
      }
      .usagechart {
        font-family: Lato, sans-serif;
        height: 100%;
      }
      rect.background-bars {
        &.active {
          fill: darken(#ffffff, 15%);
        }
      }
      .positive {
        fill: colours.$whiteLabelUsage;
      }

      .negative {
        fill: colours.$whiteLabelSolarExport;
      }

      .averages {
        circle {
          &.hidden {
            display: none;
          }
          fill: colours.$whiteLabelAverage;
        }
      }

      .graph-tooltip {
        position: absolute;
        z-index: 10;
        visibility: hidden;
        background: #000;
      }
    }

    .legend-container {
      padding-bottom: 50px;
      .legend {
        margin: auto;
        width: 50% !important;
        ul {
          padding: 0px;
          list-style-type: none;
        }

        li {
          .title {
            svg,
            p {
              margin: auto;
            }
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 10px;
            overflow: auto;

            p {
              margin-top: 5px;
              margin-bottom: 0px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .graph-container {
    overflow-y: hidden;
    .chart {
      width: 100%;

      .x-axis {
        .tick {
          text {
            font-size: 20px;
          }
        }
      }
    }
    .usagechart {
      font-family: Lato, sans-serif;
      height: 100%;
    }
    rect.background-bars {
      &.active {
        fill: darken(#ffffff, 15%);
      }
    }
    .positive {
      fill: colours.$whiteLabelUsage;
    }

    .negative {
      fill: colours.$whiteLabelSolarExport;
    }

    .averages {
      circle {
        &.hidden {
          display: none;
        }
        fill: colours.$whiteLabelAverage;
      }
    }

    .graph-tooltip {
      position: absolute;
      z-index: 10;
      visibility: hidden;
      background: #000;
    }
  }

  .legend-container {
    padding-bottom: 50px;
    .legend {
      margin: auto;
      width: 50% !important;
      ul {
        padding: 0px;
        list-style-type: none;
      }

      li {
        .title {
          svg,
          p {
            margin: auto;
          }
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 10px;
          overflow: auto;

          p {
            margin-top: 5px;
            margin-bottom: 0px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@include md-up {
  .legend-tooltip-container {
    background-color: #2c365d;
    padding: 25px;
    .close, .legend-tooltip-title {
      display: none;
    }
    position: absolute;
    max-width: 180px;
    &:not(.top):after {
      content: "";
      position: absolute;
      bottom: 0;
      left: calc(50% - 5px);
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: #2c365d;
      border-bottom: 0;
      margin-bottom: -10px;
    }

    &.top:before {
      content: "";
      position: absolute;
      bottom: 0;
      top: -20px;
      left: calc(50% - 5px);
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: #2c365d;
      transform: rotate(180deg);
    }
  }
}

@include md-down {
  .legend-tooltip-container {
     background-color: #A7ECFC;
     display: grid;
     grid-template-rows: 1fr auto auto 1fr 15px;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;

     .close {
        margin: auto;
	grid-row: 4;
        margin-bottom: 0px;
        color: #020143;
        font-size: 5.6vh;
     }
  
     .legend-tooltip-title {
         grid-row: 2;
         font-weight: 800;
         font-size: 6vh;
         color: #020143;
     }

     .legend-tooltip-content { 
         grid-row: 3;
         color: #020143;
         font-weight: 400;
         font-size: 2.5vh;
     }
  }
}

@include md-down-portrait {
  .legend-tooltip-container {
     .close {
        font-size: 5.6vh;
     }
  
     .legend-tooltip-title {
         font-size: 4.8vh;
     }

     .legend-tooltip-content { 
         font-size: 2.5vh;
         padding-left: 50px;
         padding-right: 50px;
     }
  }
}

@include md-down-landscape {
  .legend-tooltip-container {
     .close {
        font-size: 12vh;
     }
  
     .legend-tooltip-title {
         font-size: 8.8vh;
     }

     .legend-tooltip-content { 
         font-size: 7.6vh;
         padding-left: 50px;
         padding-right: 50px;
     }
  }
}



@media only screen and (max-width: 1500px) {
  .graph-container {
    overflow-x: auto;
  }
}

@media only screen and (min-width: 1501px),
  screen and (min-width: 1000px) and (orientation: landscape) {
  .graph-container {
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 500px) {
  .legend-container {
    padding-bottom: 0px;
    .legend {
      ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      }
      li {
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (min-width: 501px) {
  .legend-container {
    .legend {
      ul {
        display: grid;
        grid-template-columns: repeat(var(--legend-section-count, 4), auto);
      }
    }
  }
}
