.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-weight: bold;
  font-size: 24px;
  width: 36px;
  height: 36px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  $nav-margin: 20px;

  &.back {
    margin-left: $nav-margin;
    &:not(.disabled) {
      background-image: url("https://cdn.billcap.com/momentum/nav-back.png");
      background-repeat: no-repeat;
    }
  }

  &.forward {
    margin-right: $nav-margin;
    margin-left: 20px;
    &:not(.disabled) {
      background-image: url("https://cdn.billcap.com/momentum/nav-forward.png");
      background-repeat: no-repeat;
    }
  }
}
