.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 15px 35px;
  margin: 0px -50px;
}

.footer {
  text-align: left;
  padding: 50px 35px;
  margin: 0px -50px;

  .footer-links {
    display: grid;
    margin-left: 20px;
    grid-template-columns: auto auto auto 1fr;
    a {
      text-decoration: none;
      color: #2cd9a8;
      font-weight: 400;
    }

    a:not(:last-child) {
      margin-right: 2px;
      &::after {
        content: " | ";
        color: #2cd9a8;
        font-weight: 400;
      }
    }
  }
}

.header,
.footer {
  background-color: #001b63;
  img.product-logo {
    width: 250px;
    margin-right: 20px;
  }

  img.logo {
    width: 270px;
  }

  .company-details,
  .disclaimer {
    color: #ffffff;
    font-weight: 300;
    margin-left: 20px;
  }

  .company-details {
    font-size: 16px;
    font-weight: 500;
  }

  .disclaimer {
    font-size: 14px;
    margin: 20px 20px;
    font-weight: 400;

    a {
      text-decoration: none;
      color: #2cd9a8;
      font-weight: 400;
    }
  }
}

@media only screen and (hover: none) and (orientation: portrait),
  screen and (orientation: portrait) and (max-width: 500px) {
  .product-logo {
    display: none;
  }

  .footer-links {
    a {
      font-size: 14px;
    }
  }
}

@media only screen and (min-width: 901px) {
  .footer-links {
    a {
      font-size: 14px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .header {
    width: 100% !important;
    margin: 0px;
    padding: 0px;
  }

  .footer {
    width: 100% !important;
    margin: 0px;
    padding: 50px 0px;
  }
  .header,
  .footer {
    img.logo {
      width: 200px;
      padding: 10px;
    }
  }
}
