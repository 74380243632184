@import "/public/responsive";
@import "./NavLinks.scss";

.insights-panel {
  color: #2c365d;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 40px;
  margin-top: 25px;

  .insights-content {
    position: relative;
    width: 100%;
    display: flex;
    padding: 0 20px;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    .insight {
      max-width: 236px;
      flex: 1;
      display: flex;
      padding: 24px;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      margin-bottom: 24px;

      .insight-icon {
        height: 77px;
        img {
          width: auto;
          height: auto;
          margin-bottom: 16px;
        }
      }

      .insight-headline,
      .insight-byline {
        height: auto;
        text-align: center;
        font-family: Lato;
        font-weight: 700;
        line-height: normal;
        font-stretch: normal;
        text-decoration: none;
      }

      .insight-headline {
        font-size: 30px;
        margin-bottom: 16px;
      }

      .insight-byline {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
  }
}

@include md-down {
  .insights-panel {
    .insights-content {
      max-width: 250px;
      padding: 0;

      .insight:not(.selected) {
        display: none;
      }
    }
  }
}

@include lg-only {
  .insights-content {
    padding: 5px;

    .insight {
      min-width: 230px;
      padding: 5px;
    }
  }
}

@include md-up {
  .insights-nav-direction {
    display: none;
  }

  .insights-panel .insights-content {
    justify-content: space-evenly;

    &.insights-2 {
      width: 50%;
    }
  }
}
