.insights-type-links {
  align-self: end;
  display: grid;
  margin-left: 5px;
  margin-right: 20px;
  margin-left: 24px;
  margin-bottom: 4px;
  grid-template-columns: auto 1fr auto;

  .dailyToggle {
    &:visited {
      color: inherit;
    }

    color: #2c365d !important;
    text-decoration: none;
    font-weight: 800;
    font-size: 1.25rem;

    &:first-child {
      margin-right: 24px;
    }
    &.activeMode {
      border-bottom: 4px solid #4eeeca;
    }
  }
}

@media only screen and (max-width: 900px) {
  .insights-type-links {
    .desktop-label {
      display: none;
    }
  }
}
