@use "./shared.scss" as shared;

.utility-page {
  .main-section {
    min-height: 80vh;
    display: grid;
    position: relative;
    grid-template-rows: 1fr auto auto 1fr;
    justify-items: center;

    .headline {
      grid-row-start: 2;
      font-weight: 400;
      font-size: 36px;
      text-align: center;
    }

    .sub-heading {
      font-size: 31px;
      font-weight: 700;
      color: #001B63;
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }

    .content {
      text-align: center;
      grid-row-start: 3;
      font-weight: 400;
      font-size: 20px;

      .unsubscribe-btn {
        font-size: 16px;
        font-weight: 900;
        color: #000045;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }
      .underline {
        text-decoration: underline;
      }
    }

    button:not(.alert button) {
      padding: 20px 40px;
      background-color: #2cd9a8;
      border-radius: 100px;
      font-weight: 700;
      font-size: 22px;
      font-family: Calibri, sans-serif;
      margin-top: 50px;
      border: none;
      color: #2c365d;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1005px) {
  .utility-page .main-section {
    padding-left: 12vw;
    padding-right: 12vw;
  }
}

@media only screen and (max-width: 900px) and (orientation: portrait) {
  .utility-page .main-section {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  .utility-page .main-section {
    margin: 0px 15px;
  }
}
